import React from 'react';
import '../assets/styles/Global.scss';
import moneyIcon from '../assets/images/icons/money-icon.svg';

import ozon from '../assets/images/companies/ozon.png';
import alf from '../assets/images/companies/alf.png';
import tin from '../assets/images/companies/tin.jpeg';
import ingo from '../assets/images/companies/ingo.png';
import yandeks from '../assets/images/companies/yandeks.jpeg';
import voxys from '../assets/images/companies/voxys.png';
import yand from '../assets/images/companies/yand.png';
import vv from '../assets/images/companies/vv.jpeg';
import yandla from '../assets/images/companies/yandla.png';
import cdek from '../assets/images/companies/cdek.jpeg';
import burger from '../assets/images/companies/burger.jpeg';
import met from '../assets/images/companies/met.png';
import sbermark from '../assets/images/companies/sbermark.png';
import sam from '../assets/images/companies/sam.png';

const VacanciesSection = () => {
    return (
        <div id="vacanciesSection" className="vacancies-section">
            <h3 className="vacancies-section__title">Выберите подходящую работу</h3>
            <div className="vacancies-section__container">

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={tin}/>
                        <div className="card-company__info">
                            <p className="company-title">Удаленный сотрудник-консультант (подработка)</p>
                            <p className="company-format">Удаленная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 80 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Решение вопросов юридических лиц, проведение консультаций.
                        Быстрый карьерный рост, сумма вознаграждения увеличивается с ростом в должности.</p>
                    <a target="_blank" href="https://streamreg.ru/go/6627deffc597608920" className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={tin}/>
                        <div className="card-company__info">
                            <p className="company-title">Удаленный сотрудник-оператор (работа из дома, подработка)</p>
                            <p className="company-format">Удаленная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 70 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Ведение переговоров с клиентами, продажа продуктов.
                        Быстрый карьерный рост, сумма вознаграждения увеличивается с ростом в должности.</p>
                    <a target="_blank" href="https://streamreg.ru/go/6627deffc597608920" className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={tin}/>
                        <div className="card-company__info">
                            <p className="company-title">Менеджер по обработке данных (удаленная работа, подработка)</p>
                            <p className="company-format">Удаленная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 70 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Консультация клиентов на входящих обращения, ведение переговоров.
                        Быстрый карьерный рост, сумма вознаграждения увеличивается с ростом в должности.</p>
                    <a target="_blank" href="https://streamreg.ru/go/6627deffc597608920" className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={tin}/>
                        <div className="card-company__info">
                            <p className="company-title">Специалист клиентской поддержки (удаленная работа)</p>
                            <p className="company-format">Удаленная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 70 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Проведение консультаций, оперативное решение вопросов клиентов.
                        Быстрый карьерный рост, сумма вознаграждения увеличивается с ростом в должности.</p>
                    <a target="_blank" href="https://streamreg.ru/go/6627deffc597608920" className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={ingo}/>
                        <div className="card-company__info">
                            <p className="company-title">Менеджер по продажам страховых услуг (свой график)</p>
                            <p className="company-format">Удаленная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 150 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Возможность работать из дома, гибкий график от 2-х дней в неделю.
                        Предоставляется рабочий ноутбук и наставник, ДМС со стоматологией в соцпакете.</p>
                    <a target="_blank" href="https://tracking.lovko.pro/click?pid=1322&offer_id=70"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={tin}/>
                        <div className="card-company__info">
                            <p className="company-title">Менеджер по работе с клиентами (удаленная работа,
                                подработка)</p>
                            <p className="company-format">Удаленная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 74 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Оперативное решение вопросов клиентов, помощь с предоставляемыми услугами.
                        Быстрый карьерный рост, сумма вознаграждения увеличивается с ростом в должности.</p>
                    <a target="_blank" href="https://streamreg.ru/go/6627deffc597608920" className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={yandeks}/>
                        <div className="card-company__info">
                            <p className="company-title">Менеджер по продажам (подработка, удаленная работа)</p>
                            <p className="company-format">Удаленная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>от 48 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Официальное трудоустройство с возможностью частичной занятости.
                        Оплачиваемое обучение перед стартом (удалённое). Возможность для роста и развития.</p>
                    <a target="_blank" href="https://trk.ppdu.ru/click/NyK04sPl?erid=2SDnjcGdzVw"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={voxys}/>
                        <div className="card-company__info">
                            <p className="company-title">Специалист центра коммуникаций (удаленная работа)</p>
                            <p className="company-format">Удаленная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 45 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Гибкий график: 5/2, 2/2, 3/3. Подработка от 4 часов, возможность
                        совмещения с учебой.
                        Выбор проекта: входящие звонки, исходящие звонки. Оплачиваемое обучение с опытными
                        наставниками.</p>
                    <a target="_blank" href="https://my.saleads.pro/s/xkj2z?erid=2VtzqvBAeUv"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={alf}/>
                        <div className="card-company__info">
                            <p className="company-title">Специалист по дистанционной работе с клиентами</p>
                            <p className="company-format">Удаленная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>от 52 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Полностью официальное трудоустройство уже на этапе обучения.
                        Возожность удаленной работы с перспективами для быстрого карьерного развития. Гибкий график,
                        который можно
                        подстроить под себя. </p>
                    <a target="_blank" href="https://pxl.leads.su/click/df45914a0f8e102f3b7353d975df22d6?erid=LjN8K4MYx"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={yandla}/>
                        <div className="card-company__info">
                            <p className="company-title">Кладовщик</p>
                            <p className="company-format">Иная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 106 800₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Официальное оформление, выплаты 2 раза в месяц на карту.
                        Подобор наиболее близкого склада к вашему дому. График работы на выбор, возможность подстроить
                        под себя.</p>
                    <a target="_blank" href="https://trk.ppdu.ru/click/2lASGjNI?erid=2SDnjdu4gFa"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={cdek}/>
                        <div className="card-company__info">
                            <p className="company-title">Кладовщик-комлектовщик</p>
                            <p className="company-format">Иная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 115 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Официальное оформление с первого дня, ежемесячные бонусы. Оплачиваемое
                        обучение. График работы 4/4. Возможность карьерного роста.</p>
                    <a target="_blank" href="https://pxl.leads.su/click/c7684e58bee8bca21b283333823e368d?erid=LjN8KSVpT"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={ozon}/>
                        <div className="card-company__info">
                            <p className="company-title">Работник склада</p>
                            <p className="company-format">Иная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>от 98 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Трудоустройство по ТК РФ с первого рабочего дня. Сменный график работы, который
                    можете подстроить под себя. Премии, надбавки за смены в ночь. </p>
                    <a target="_blank" href="https://tracking.lovko.pro/click?pid=1322&offer_id=22"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={sam}/>
                        <div className="card-company__info">
                            <p className="company-title">Пеший/вело курьер партнёрской доставки</p>
                            <p className="company-format">Иная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 145 280₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Быстрое подключение и оплачиваемое двухчасовое обучение, гибкие слоты.
                        Гарантированный еженедельный доход, без задержек. </p>
                    <a target="_blank" href="https://tracking.lovko.pro/click?pid=1322&offer_id=90"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={yand}/>
                        <div className="card-company__info">
                            <p className="company-title">Пеший/вело курьер</p>
                            <p className="company-format">Иная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 4 500₽ в день</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Доставка в районе 3 км, гарантированный доход ежедневно, без задержек.
                        Возможность совмещения с учебой или с работой, гибкий график, свободная подработка.</p>
                    <a target="_blank" href="https://my.saleads.pro/s/eugjc?erid=2VtzqwKskgW"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={vv}/>
                        <div className="card-company__info">
                            <p className="company-title">Велокурьер</p>
                            <p className="company-format">Иная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>от 5 000₽ в день</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Фиксируемый радиус доставки, ежедневные выплаты, никаких задержек.
                        Возможность совмещения с учебой или с работой, гибкий график.</p>
                    <a target="_blank" href="https://pxl.leads.su/click/a74e80b24d34085c2ba2d5358b95cd47?erid=LjN8JuLxX"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={yandla}/>
                        <div className="card-company__info">
                            <p className="company-title">Партнер курьерской доставки</p>
                            <p className="company-format">Иная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>от 100 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Подключение и первый заказ в течение суток. Еженедельные выплаты.
                        Возможность совмещения с учебой или работой, доставка по закрепленному району.</p>
                    <a target="_blank" href="https://trk.ppdu.ru/click/B8nicXau?erid=Kra23uVC3"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={ozon}/>
                        <div className="card-company__info">
                            <p className="company-title">Курьер на личном авто и авто компании</p>
                            <p className="company-format">Иная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>от 77 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Личное авто / автомобиль компании. Возможность выбора ближайшего склада.
                        Трудоустройство по ТК РФ с первого рабочего дня.</p>
                    <a target="_blank" href="https://tracking.lovko.pro/click?pid=1322&offer_id=82"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={sbermark}/>
                        <div className="card-company__info">
                            <p className="company-title">Курьер на личном автомобиле/пеший курьер</p>
                            <p className="company-format">Иная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 145 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Возможность выбора района доставки, свободный график, льготное
                        обслуживание транспорта.
                        Трудоустройство с первого дня.</p>
                    <a target="_blank" href="https://trk.ppdu.ru/click/pETkPa2T?erid=2SDnjeL6Zwp"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={vv}/>
                        <div className="card-company__info">
                            <p className="company-title">Повар</p>
                            <p className="company-format">Иная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 55 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Трудоустройство по ТК РФ с первого рабочего дня, обучение.
                        Компенсация за питание, а также медицины и фитнеса 50%.</p>
                    <a target="_blank" href="https://streamreg.ru/go/65d46bf33f8ce08920"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={vv}/>
                        <div className="card-company__info">
                            <p className="company-title">Бариста</p>
                            <p className="company-format">Иная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 78 300₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">Трудоустройство по ТК РФ с первого рабочего дня, обучение.
                        Компенсация за питание, а также медицины и фитнеса 50%.</p>
                    <a target="_blank" href="https://streamreg.ru/go/65d46bf33f8ce08920"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

                <div className="vacancie-card">
                    <div className="card-company">
                        <img className="card-company__logo" src={burger}/>
                        <div className="card-company__info">
                            <p className="company-title">Повар-кассир</p>
                            <p className="company-format">Иная работа</p>
                        </div>
                    </div>
                    <div className="card-money">
                        <img src={moneyIcon}/>
                        <p>до 96 000₽</p>
                    </div>
                    <p className="offer-title"></p>
                    <p className="offer-desc">График работы на выбор, возможна свободная подработка.
                        Вкусное питание и красивая форма от компании, обучение.</p>
                    <a target="_blank" href="https://streamreg.ru/go/665dc82b3d04208920"
                       className="offer-button">Откликнуться
                        на вакансию</a>
                </div>

            </div>
        </div>
    );
}

export default VacanciesSection;